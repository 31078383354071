body {
  background-color: black;
  color: white;
  text-align: center;
  font-size: 40px;
  align-items: center;
  margin: 100px;
}

#home {
  font-size: 16px;
  border-radius: 80px;
  color: white;
  background-color: black;
  border-width: 10px;
  border-color: yellow;
  border: solid;
}

#padder {
  margin: 10px;
  padding: 10px;
}

#scoreSection {
  font-size: 25px;
  min-height: 2em;
}

#scoreValue {
  font-size: 40px;

  font-weight: 200px;
  color: lightgreen;
}

#titleSection {
  font-size: 40px;
}

#instructionSection {
  font-size: 16px;
}

.imgmaxi {
  height: 200px;
  width: 150px;
  background-color: black;
  color: white;
  vertical-align: top;
}

.imgmax {
  height: 190px;
  width: 140px;
}

.imgactor {
  height: 85px;
  width: 65px;
}

.imgchain {
  height: 100px;
  width: 75px;
  color: white;
  background-color: black;
  vertical-align: top;
  font-size: 12px;
  border: none;
}

.imgchainM {
  height: 110px;
  width: 80px;
  background-color: black;
}

.imgchainA {
  height: 70px;
  width: 60px;
  background-color: black;
  color: white;
  vertical-align: top;
  border: none;
  font-size: 12px;
}

.imgchainMA {
  height: 100%;
  width: 100%;
  background-color: black;
}

#playbutton {
  height: 140px;
  width: 350px;
  font-size: 30px;
  color: yellow;
  background-color: grey;
  border-radius: 250px;
  padding: 30px;
  margin: 45px;
  border-width: 14px;
  border-color: yellow;
  border: solid;
}

#playbutton:focus {
  outline: none;
  height: 140px;
  width: 350px;
  font-size: 30px;
  color: yellow;
  background-color: grey;
  border-radius: 250px;
  padding: 30px;
  margin: 45px;
  border-width: 14px;
  border-color: yellow;
  border: solid;
}

@media only screen and (max-width: 800px) {
  body {
    background-color: black;
    color: white;
    text-align: center;
    font-size: 14px;
    align-items: center;
    margin: 10px;
  }

  .imgmaxi {
    height: 100px;
    width: 75px;
    background-color: black;
    border: none;
  }

  .imgmax {
    height: 100%;
    width: 100%;
  }

  .imgactor {
    height: 80px;
    width: 60px;
  }

  .imgchain {
    height: 70px;
    width: 50px;
    background-color: black;
    color: white;
    vertical-align: top;
    border: none;
  }

  .imgchainM {
    height: 100%;
    width: 100%;
    background-color: black;
  }

  .imgchainA {
    height: 70px;
    width: 60px;
    background-color: black;
    color: white;
    vertical-align: top;
    border: none;
    font-size: 12px;
  }

  .imgchainMA {
    height: 100%;
    width: 100%;
    background-color: black;
  }

  #playbutton {
    height: 75px;
    width: 100px;
    font-size: 18px;
    color: yellow;
    background-color: grey;
    border-radius: 250px;
    padding: 5px;
    margin: 10px;
    border-width: 12px;
    border-color: yellow;
    border: solid;
  }

  #playbutton:focus {
    outline: none;
    height: 75px;
    width: 100px;
    font-size: 18px;
    color: yellow;
    background-color: grey;
    border-radius: 250px;
    padding: 5px;
    margin: 10px;
    border-width: 12px;
    border-color: yellow;
    border: solid;
  }

  #padder {
    margin: 10px;
    padding: 10px;
  }

  .instruction {
    margin-top: 40px;
  }

  #scoreSection {
    font-size: 20px;
    min-height: 2em;
  }

  #instructionSection {
    font-size: 18px;
    min-height: 2em;
  }

  h3 {
    min-height: 3em;
  }

  .focus {
    font-size: 20px;
    min-height: 1em;
  }

  #home {
    font-size: 12px;
    border-radius: 80px;
    color: yellow;
    background-color: black;
    border-width: 6px;
    border-color: yellow;
    border: solid;
  }

  #titleSection {
    font-size: 30px;
  }
}
